import I18n from 'i18n';

let displayLoaderTimer;
let startTimer;
let $loader;
let $content;

function injectLoader() {
  const logo = $('.navbar-brand').html();

  $loader = $(`<div class="trial-loader">
    <div class="content">
      <ef-icon name="spinner-third"></ef-icon>
      <div class="logo">${logo}</div>
    </div>
  </div>`).hide().appendTo(document.body);
  $content = $loader.find('.content');
  $loader.fadeIn();
}

function removeDomLoader() {
  $loader?.fadeOut();
}

function addMessage(msg, tag = 'p') {
  $content.append(`<${tag}>${msg}</${tag}>`);
}

function start() {
  const icon = '<ef-icon name="shield-check"></ef-icon>';
  const messages = [
    'message1',
    'message2',
    'message3',
    'message4',
    'message5',
  ].map(text => `${icon} ${I18n.t(text, { scope: 'trials.loader' })}`);

  messages.push('...');

  addMessage($('#community_title').val(), 'h1');

  startTimer = setInterval(() => {
    if (!messages.length) {
      clearInterval(startTimer);
      return;
    }

    addMessage(messages.shift());
  }, 1000);
}

function displayLoader() {
  displayLoaderTimer = setTimeout(() => {
    injectLoader();
    start();
  }, 1000);
}

function clear() {
  clearTimeout(displayLoaderTimer);
  clearInterval(startTimer);
}

function removeLoader() {
  removeDomLoader();
}

$(document)
  .on('ajax:send', '.js-trials #new_community', () => {
    displayLoader();
  })
  .on('ajax:complete', '.js-trials #new_community', () => {
    clear();
  })
  .on('ajax:success', '.js-trials #new_community', (e) => {
    // eslint-disable-next-line no-unused-vars
    const [html, status, xhr] = e.detail;

    const contentType = xhr.getResponseHeader('content-type') || '';
    if (!contentType.includes('html')) { return; }

    const hasErrors = $(html).find('.has-error').length;
    if (hasErrors) {
      removeLoader();
    }
  });
