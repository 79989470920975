import I18n from 'i18n';
import Snack from '@effectivastudio/snackbar';
import DataBinder from 'lib/data_binder';

function GroupSchema(d) {
  if (d.isFavorite === 'toggle') {
    d.isFavorite = !this.hasPart('.js-toggle-favorite', 'active');
    d.isFavoriteIcon = d.isFavorite ? 'star-s' : 'star';
  }

  // i18n-tasks-use t("community.remove_as_contact_person")
  // i18n-tasks-use t("community.set_as_contact_person")
  if (d.isContactPerson === 'toggle') {
    d.isContactPerson = !this.hasPart('.js-toggle-contact-person', 'active');
    d.isContactPersonText = I18n.t(d.isContactPerson ? 'community.remove_as_contact_person' : 'community.set_as_contact_person');
    d.isContactPersonIcon = d.isContactPerson ? 'user-headset-s' : 'user-headset';
  }

  // i18n-tasks-use t("community_member.actions.lock")
  // i18n-tasks-use t("community_member.actions.unlock")
  if (d.isLocked === 'toggle') {
    d.isLocked = !this.hasPart('.js-toggle-lock', 'active');
    d.isLockedText = I18n.t(d.isLocked ? 'community.unlock' : 'community.lock');
    d.isLockedIcon = d.isLocked ? 'user-lock-o' : 'user-lock';
  }

  // i18n-tasks-use t("common.add_to_favorites.success_msg")
  // i18n-tasks-use t("common.remove_from_favorites.success_msg")
  // i18n-tasks-use t("community.set_as_contact_person_snack_msg")
  // i18n-tasks-use t("community.remove_as_contact_person_snack_msg")

  if (d.snackMsg) {
    const isActive = this.el.part?.contains?.('active');

    if (d.snackMsg === 'favorite') {
      Snack({ text: I18n.t(isActive ? 'common.add_to_favorites.success_msg' : 'common.remove_from_favorites.success_msg') });
    }
    if (d.snackMsg === 'contact') {
      Snack({ text: I18n.t(isActive ? 'community.set_as_contact_person_snack_msg' : 'community.remove_as_contact_person_snack_msg') });
    }

    if (d.snackMsg === 'lock') {
      Snack({ text: I18n.t(isActive ? 'community.lock_snack_msg' : 'community.unlock_snack_msg') });
    }
  }

  this
    .togglePart('.js-toggle-favorite', 'active', d.isFavorite)
    .togglePart('.js-toggle-contact-person', 'active', d.isContactPerson)
    .togglePart('.js-toggle-lock', 'active', d.isLocked)
    .attr('.js-toggle-favorite ef-icon', 'name', d.isFavoriteIcon)
    .attr('.js-toggle-contact-person', 'name', d.isContactPersonText)
    .attr('.js-toggle-contact-person', 'icon', d.isContactPersonIcon)
    .attr('.js-toggle-lock', 'name', d.isLockedText)
    .attr('.js-toggle-lock', 'icon', d.isLockedIcon);
}

export default DataBinder.create(GroupSchema);
