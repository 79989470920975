/** * Calculate page offset when modal is shown and restor it afterwards ** */
/** * Only for screens below 768px ** */

let scrollPosition;
$(document).on('show.bs.modal scrollPosition:save', (e) => {
  if ($(window).width() >= 768) { return; }
  if (e.target.hasAttribute('data-provide')) { return; } // check event namespace as boostrap datepicker triggers bootstrap modal "show.bs.modal"

  scrollPosition = window.pageYOffset;
  $('main').css({ top: `${-scrollPosition}px`, position: 'relative' });
});

$(document).on('hidden.bs.modal scrollPosition:restore', () => {
  if ($(window).width() >= 768) { return; }
  $('main').css({ top: 'auto', position: 'static' });
  window.scrollTo(0, scrollPosition);
});
