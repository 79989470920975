import { reloadElement } from 'src/utils';

$(document)
  .on('group_join_request.update.fm', (e, data) => {
    const messages = document.querySelectorAll(`[data-hide-id="${data.id}"]`);

    messages.forEach((item) => {
      const list = item.closest('.message-list');

      item.parentNode.removeChild(item);
      list?.children.length === 0 && list.parentNode.removeChild(list);
    });
  })
  .on('ajax:success', '.form-invite-new-user, .form-invite-existing-user', () => {
    reloadElement('.members-list');
  });
