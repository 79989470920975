import { AppConfig } from 'src/configuration';
import { requirePhoneConfirmation } from 'src/lib/require_phone_confirmation';

$(document)
  .on('phone_wizard.any.fm', (e, data) => {
    App.modalData = data.params;
    data.params.confirmed && (AppConfig.user.isConfirmed = true);
  })

  .on('ajax:beforeSend', '.js-resend-sms', function ajaxBeforeSendOnResendSms() {
    this.classList.add('js-disabled');
  });

// Raise phone confirmation wizard and redirect to link url after
$(document).on('click', '[data-require-confirmed-user="true"]', (e) => {
  if (!AppConfig.user.isConfirmed) {
    requirePhoneConfirmation(`/phone/edit?turbolinks_url=${e.currentTarget.href}`) && e.preventDefault();
  }
});
