import { AppConfig } from 'src/configuration';
import * as Sentry from '@sentry/browser';

AppConfig.env.SENTRY_ON && Sentry.init({
  dsn: AppConfig.env.SENTRY_DSN,
  environment: AppConfig.env.RAILS_ENV,
  release: AppConfig.env.SENTRY_RELEASE,
  // debug: true, // turn this on for debugging...
  ignoreErrors: [/ResizeObserver loop completed with undelivered notifications/],
  tracesSampleRate: 0.9,
  integrations: [
    // Attaches global handlers to capture uncaught exceptions and unhandled rejections.
    Sentry.globalHandlersIntegration({ onunhandledrejection: false }),
    // Send console.error to Sentry
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
  ],
  beforeSend(event) {
    const appVersion = AppConfig.runtime.mobile_app_version;

    Sentry.setTags({
      ...(appVersion && { appVersion }),
    });

    Sentry.setUser({ id: AppConfig.user.id });

    return event;
  },
});
