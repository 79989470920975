import _padStart from 'lodash/padStart';

const elementToDateArray = el => el.value.split('-');
const elementToTimeArray = el => el.value.split(':');

const parseNativeDateFromElements = (dateEl, timeEl) => {
  const [year, month, day] = elementToDateArray(dateEl);
  const [hours, minutes] = timeEl ? elementToTimeArray(timeEl) : [0, 0];
  return new Date(year, month - 1, day, hours, minutes);
};

const parsePickerDateFromElements = (dateEl, hourEl, minEl) => {
  const [day, month, year] = dateEl.value.split('/').map(n => parseInt(n, 10));
  const hours = hourEl ? hourEl.value : 0;
  const minutes = minEl ? minEl.value : 0;
  return new Date(year, month - 1, day, hours, minutes);
};

const addHourToDate = (date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 1);
  return newDate;
};

const dateToString = (d) => {
  const year = d.getFullYear();
  const month = _padStart(d.getMonth() + 1, 2, 0);
  const day = _padStart(d.getDate(), 2, 0);
  const hours = _padStart(d.getHours(), 2, 0);
  const minutes = _padStart(d.getMinutes(), 2, 0);

  return {
    dateNative: `${year}-${month}-${day}`,
    datePicker: `${day}/${month}/${year}`,
    time: `${hours}:${minutes}`,
    year,
    month,
    day,
    hours,
    minutes,
  };
};

const setupUI = $c => ({
  // Shared
  startDate: $c.querySelector('[data-smart-picker-name="start"] input'),
  endDate: $c.querySelector('[data-smart-picker-name="end"] input'),

  // Native
  startTime: $c.querySelector('[data-smart-picker-name="start"] input[type="time"]'),
  endTime: $c.querySelector('[data-smart-picker-name="end"] input[type="time"]'),

  // Picker
  startHour: $c.querySelector('[data-smart-picker-name="start"] select:nth-child(1)'),
  startMinutes: $c.querySelector('[data-smart-picker-name="start"] select:nth-child(2)'),

  endHour: $c.querySelector('[data-smart-picker-name="end"] select:nth-child(1)'),
  endMinutes: $c.querySelector('[data-smart-picker-name="end"] select:nth-child(2)'),
});

const smartDatePicker = (container) => {
  if (!container) { return false; }

  const isNative = container.dataset.smartPickerType === 'native';
  const ui = setupUI(container);

  let startDate;
  let endDate;

  if (isNative) {
    startDate = parseNativeDateFromElements(ui.startDate, ui.startTime);
    endDate = parseNativeDateFromElements(ui.endDate, ui.endTime);
  } else {
    startDate = parsePickerDateFromElements(ui.startDate, ui.startHour, ui.startMinutes);
    endDate = parsePickerDateFromElements(ui.endDate, ui.endHour, ui.endMinutes);
  }

  if (startDate < endDate) { return false; }

  const newEndDate = addHourToDate(startDate);
  const { dateNative, time, datePicker, hours, minutes } = dateToString(newEndDate);

  if (isNative) {
    ui.endDate.value = dateNative;
    if (ui.endTime) { ui.endTime.value = time; }
  } else {
    ui.endDate.value = datePicker;
    if (ui.endHour) { ui.endHour.value = hours; }
    if (ui.endMinutes) { ui.endMinutes.value = minutes; }
  }

  return {
    newEndDate,
    ui,
  };
};

$(document).on('change', '[data-smart-picker-name="start"] input, [data-smart-picker-name="start"] select', (e) => {
  smartDatePicker(e.target.closest('.js-set-date'));
});

// Testing api
export const __private__ = {
  elementToDateArray,
  elementToTimeArray,
  parseNativeDateFromElements,
  parsePickerDateFromElements,
  addHourToDate,
  dateToString,
};

export default smartDatePicker;
