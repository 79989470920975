/* eslint no-underscore-dangle: ["error", { "allow": ["_paq"] }] */

import { AppConfig } from 'src/configuration';

// Send Matomo a new event when navigating to a new page using Turbo
// (see https://developer.matomo.org/guides/spa-tracking)

function track(name, ...values) {
  window._paq || (window._paq = []);

  window._paq.push([name, ...values]);
}

function setCustomDimension({ id, value }) {
  track('setCustomDimension', id, value);
}

let previousPageUrl = null;
document.addEventListener('turbolinks:load', (event) => {
  if (!window._paq) { return; }

  if (previousPageUrl) {
    track('setReferrerUrl', previousPageUrl);
    track('setCustomUrl', window.location.href);
    track('setDocumentTitle', document.title);
    const timing = event?.data?.timing;

    if (timing) {
      track('setPagePerformanceTiming', timing.visitEnd - timing.visitStart);
    }

    setCustomDimension({ id: 1, value: AppConfig.runtime.isMobileApp ? 'MobileApp' : 'BrowserApp' });

    track('deleteCustomVariables', 'page');
    track('trackPageView');
  }
  previousPageUrl = window.location.href;
});
