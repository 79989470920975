const popoverSelector = '[data-toggle="popover"]';

const tooltipsInit = (el) => {
  const tooltip = el || document.querySelectorAll(popoverSelector);
  if (!tooltip.length) { return; }

  tooltip.forEach((item) => {
    $(item).popover({
      /* Options: https://getbootstrap.com/docs/4.0/components/popovers */
      placement: 'auto',
      container: 'body',
      trigger: 'focus',
      html: true,
      viewport: { selector: 'body', padding: 8 },
    });
  });
};

// Install observer
const editorObserver = (n) => {
  tooltipsInit(n.querySelectorAll(popoverSelector));
};

App.mutation_observers.push(editorObserver);

document.addEventListener('turbolinks:load', () => {
  tooltipsInit();
});
