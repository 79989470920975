function markCurrentLinkForPath(documentRoot, path) {
  if (!path) { return; }

  const finalPath = path.split('?')[0];
  const links = documentRoot.querySelectorAll(`.js-current-link a[href="${finalPath}"]`);
  let parent;

  links.forEach((link) => {
    parent = link.parentNode;
    if (!parent.classList.contains('active')) {
      const previouslyActives = parent.parentNode.querySelectorAll('.active');
      previouslyActives.forEach((n) => { n.classList.remove('active'); });
      parent.classList.add('active');
    }
  });
}

function currentLink(e) {
  const isPreview = document.documentElement.hasAttribute('data-turbolinks-preview');
  if (isPreview) { return; }

  const documentRoot = (e && e.data && e.data.newBody) || document;

  const { pathname } = window.location;
  const { search } = window.location;
  const fullPath = pathname + (search ? `?${search}` : '');

  const previouslyActives = documentRoot.querySelectorAll('.js-current-link .active');
  previouslyActives.forEach(n => n.classList.remove('active'));

  const paths = fullPath.split('/');

  for (let i = 0; i <= paths.length; i++) {
    markCurrentLinkForPath(documentRoot, paths.slice(0, i).join('/'));
  }

  // For index page (pathname is empty then)
  // Sometimes turbolinks does not change url
  if (pathname === '/' || pathname.match(/authenticate|login/)) {
    markCurrentLinkForPath(documentRoot, '/dashboard');
  }
}

// We need both
document.addEventListener('DOMContentLoaded', currentLink);
document.addEventListener('turbolinks:before-render', currentLink);
