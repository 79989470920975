import { AppConfig } from 'src/configuration';

if (AppConfig.inTestEnv) {
  // Turn off animations
  $.fx.off = true;

  window.addEventListener('error', (e) => {
    document.body.innerHTML = [
      '<h1 style="text-align:center;">JS ERROR</h1>',
      '<pre>',
      e.error.stack,
      '</pre>',
    ].join('');

    document.body.setAttribute('style', 'background:#900; color:white; padding:5px;');
  });
}
