import { reloadElement } from 'src/utils';

// Upon successful Form modal submit
// Reload the current page, and replace element with the specified selector
// We are not using morphdom here because it doesn't play well with custom web components
$(document).on('custom_field_value.update.fm', () => reloadElement('.js-advance-members-table', { morph: false }));

$(document).on('click', '.js-advance-members-table .editable', (e) => {
  e.target.querySelector('a')?.click();
  document.querySelector('.edit-in-progress')?.classList?.remove?.('edit-in-progress');
  e.target.closest('td').classList.add('edit-in-progress');
});
