$(document)
  // Only for community title change
  .on('ajax:success:inline', '[data-show*="/settings/title"]', (e) => {
    const el = document.querySelector('.js-inline-update .title');

    if (!el) { return; }
    el.textContent = e.target.textContent;
  })

  // Only before community type change
  .on('ajax:success:inlineBefore', '[data-show*="/settings/type"]', (e, originalEvent) => {
    const currentValue = e.target.querySelector('select').value;
    let defaultValue;
    e.target.querySelectorAll('option').forEach((item) => {
      if (item.defaultSelected) {
        defaultValue = item.value;
      }
    });

    if (defaultValue === 'private' && currentValue !== 'private') {
      originalEvent.preventDefault();
      return;
    }

    if (defaultValue !== 'private' && currentValue === 'private') {
      originalEvent.preventDefault();
    }
  })

  // Only for community type change
  .on('ajax:success:inline', '[data-show*="/settings/type"]', (e, originalEvent) => {
    if (originalEvent.isDefaultPrevented()) { return; }

    const el = document.querySelector('.js-inline-update ef-icon-block');
    if (!el) { return; }

    el.parentNode.replaceChild(e.target.firstChild.cloneNode(), el);
  });

function handleUploadContainerEvents(e) {
  const { target } = e;
  const wrapper = target.closest('[data-update-element]');
  if (!wrapper) return;

  const url = target.croppedImgSrc;

  if (wrapper.dataset.updateElement === 'picture') {
    const el = document.querySelector('.js-inline-update picture source');
    if (!el) return;

    el.srcset = url;
  }

  if (wrapper.dataset.updateElement === 'avatar') {
    const el = document.querySelector('.js-inline-update ef-avatar');
    if (!el) return;

    el.picture = url;
  }
}

document.addEventListener('uploadContainerSubmitImg-success', (e) => {
  handleUploadContainerEvents(e);
});

document.addEventListener('uploadContainerDeleteImg-success', (e) => {
  handleUploadContainerEvents(e);
});
