import Rails from 'rails-ujs';

$(document)
  // Copy value to filter form and submit that
  .on('ajax:before', '.js-filter-main-input', (e) => {
    const { currentTarget: input } = e;

    const modalForm = document.querySelector('.js-filter-form-modal');
    if (!modalForm) { return; }

    e.preventDefault();

    const inputSelector = '[name="q[title]"]';
    const searchBarInputValue = input.querySelector(inputSelector).value;
    modalForm.querySelector(inputSelector).value = searchBarInputValue;
    Rails.fire(modalForm, 'submit');
  });
