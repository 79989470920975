import 'select2/dist/js/select2.full';
import I18n from 'i18n';

// Hitting backspace on multi - select converts element to text (https://github.com/select2/select2/issues/3354)
// Override the default select2 behavior
$.fn.select2.amd.require(['select2/selection/search'], (Search) => {
  const oldRemoveChoice = Search.prototype.searchRemoveChoice;

  Search.prototype.searchRemoveChoice = function searchRemoveChoice() {
    // eslint-disable-next-line prefer-rest-params
    oldRemoveChoice.apply(this, arguments);
    this.$search.val('');
  };
});

/* eslint-disable camelcase */
const templates = {
  group_member({ group_member, text }) {
    const groupMemberLabel = group_member ? ` <small class="label label-default">${I18n.t('community.groupmate')}</small>` : '';
    return `<span>${text}${groupMemberLabel}</span>`;
  },

  file_tag_result({ color, text }) {
    return `<span class="tag tag-v3 color-${color}">${text}</span>`;
  },

  file_tag_selection(data, container) {
    const color = data.color || data.element.dataset.color; // Data is different depending if response is from autocomplete or from template
    container.addClass(`tag tag-v3 color-${color}`);

    return data.text;
  },
};
/* eslint-enable camelcase */

const selectOptions = (select2) => {
  const $this = $(select2);
  const selectData = $this.data();
  let tagsOptions = {};

  const defaultOptions = {
    width: '100%',
    ajax: {
      url: selectData.url,
      delay: 250,
      data(params) {
        return {
          query: params.term, // search term
          page: params.page,
        };
      },
      processResults(data) {
        if ('results' in data) { return data; }
        return { results: data };
      },
      cache: true,
      tag: true,
    },
    templateResult: templates[selectData.appTemplateResult],
    placeholder: selectData.placeholder,
    escapeMarkup(markup) { return markup; }, // let our custom formatter work
    minimumInputLength: ('minimumInputLength' in selectData) ? selectData.minimumInputLength : 2,
  };

  if (select2.classList.contains('js-select2-t2')) {
    tagsOptions = {
      templateResult: templates.file_tag_result,
      templateSelection: templates.file_tag_selection,
      dropdownCssClass: 'select2-dropdown-t2',
    };
  }

  return { ...defaultOptions, ...tagsOptions };
};

function setupSelect2(scope = document) {
  scope.querySelectorAll('.js-select2').forEach((select) => {
    if (select.closest('.filter-form-wrapper')) { return; } // Content of filter-form is manually appended to modal, so there is no need to initialize twice!

    // Import select2 language based on the html lang attribute
    let lang = document.documentElement.lang ? document.documentElement.lang : 'de';

    switch (lang) {
      case 'de-CH':
        lang = 'de';
        break;
      case 'en-001':
        lang = 'en';
        break;
      // no default
    }

    /* eslint-disable */
    import('select2/src/js/select2/i18n/' + lang).then((language) => {
      $.fn.select2.defaults.set('language', language.default);
      $(select).select2(selectOptions(select));
    });
    /* eslint-enable */

    $('.attachment .select2-search:not(:has(i))').append('<ef-icon name="plus-circle"></ef-icon>');
  });
}

App.mutation_observers.push(setupSelect2);

document.addEventListener('turbolinks:load', () => {
  setupSelect2();
});
