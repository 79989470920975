import { AppConfig } from 'src/configuration';
import TurboNativeAdapter from '@effectivastudio/turbo_native_adapter';

/*
  TurboNativeAdapter will resolve itself to Turbo or Turbolinks
*/

TurboNativeAdapter.getAuthToken = () => AppConfig.user.accessToken;
TurboNativeAdapter.shouldUpdatePushToken = () => AppConfig.user.id;

if (TurboNativeAdapter.IS_TURBO) {
  document.addEventListener('notifications:total_count:update', (e) => {
    TurboNativeAdapter.setBadgeCount(e.detail.totalCount || 0);
  });
}

window.App.Mobile = TurboNativeAdapter;

TurboNativeAdapter.start();
