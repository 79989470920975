const createOption = (data) => {
  const o = document.createElement('option');
  o.value = data;
  o.innerHTML = data;
  return o;
};

$(document).on('change', '.js-smart-select [data-smart-select="master"]', (e) => {
  const container = e.target.closest('.js-smart-select');
  const master = container.querySelector('[data-smart-select="master"]');
  const slave = container.querySelector('[data-smart-select="slave"]');
  const index = master.value - 1;
  const data = JSON.parse(slave.dataset.smartSelectCollection);

  slave.options.length = 0;

  data[index].forEach((option) => {
    slave.appendChild(createOption(option));
  });
});
