import Snack from '@effectivastudio/snackbar';

const { Mobile } = App;

function unifiedAdapter(action, params) {
  switch (action) {
    case 'setAuthToken': Mobile[action](params.token); break;
    case 'triggerShare': Mobile[action](params.url); break;
    case 'downloadImage': Mobile[action](params.url, params.filename); break;
    case 'preview': Mobile[action](params.url, params.filename); break;
    case 'openInBrowser': Mobile[action](params.url); break;
    case 'setBadgeCount': Mobile[action](params.count); break;
    case 'nativeLog': Mobile[action](params.msg); break;
    // Deprecated
    case 'showUrlDialog': Mobile[action](params.val); break;
    case 'showSnackBar': Mobile[action](params.val); break;
    case 'showErrDialog': Mobile[action](params.val); break;
    // ^^^^^^^^^^
    default: Mobile[action](); break;
  }
}

$(document)
  .on('click', '.js-mobile-demo', (e) => {
    e.preventDefault();
    const { action, ...params } = e.currentTarget.dataset;
    // eslint-disable-next-line no-console
    console.log(action, params);
    try {
      const result = unifiedAdapter(action, params);
      Snack({ text: `${action} ${JSON.stringify(params)} ${result}` });
    } catch (err) {
      Snack({ text: `Mobile.${action} ${JSON.stringify(params)}: ${err}`, customClass: 'snackbar-danger' });
    }
  })
  .on('turbolinks:load', () => {
    $('.js-mobile-demo-bridge').html(`Mobile bridge: <strong>${Mobile.bridge}</strong>`);
  });
