import { AppConfig } from 'src/configuration';
import I18n from 'i18n';
import debounce from 'lodash/debounce';
import Turbolinks from 'turbolinks';
import Snack from '@effectivastudio/snackbar';
import { requirePhoneConfirmation } from 'lib/require_phone_confirmation';
import triggerEvent from 'lib/utils/trigger_event';
import CardBinder from './data_binders/card_binder';

$(document)
  .on('click', '.js-comment', function onClickComment(e) {
    e.preventDefault();
    if (!AppConfig.user.id) {
      // Unless current user prevent all card remote actions and redirect to login page. After login user will be redirected to current url
      Turbolinks.visit('/login');
      return;
    }
    requirePhoneConfirmation() ? e.preventDefault() : $(this.getAttribute('href')).collapse('toggle');
  })

  .on('ajax:beforeSend', (e) => {
    const card = e.target.closest('bu-card');

    if (card && !AppConfig.user.id) {
      // Unless current user prevent all card remote actions and redirect to login page. After login user will be redirected to current url
      e.preventDefault();
      Turbolinks.visit('/login');
      return;
    }

    const t = e.target;
    const l = t.classList;

    if (l.contains('xhr-started')) { return; }
    l.remove('xhr-completed');
    l.add('xhr-started');

    if (l.contains('js-organize-assign')) {
      e.target.parentNode.querySelectorAll('.card-organize-item-check').forEach((el) => {
        el.classList.toggle('hidden');
      });
    }

    const shouldBeSubscribed = ['EF-POLL-ITEM', 'EF-COMMENT-FORM', 'BU-DATE-FINDER-OPTION'].includes(t.tagName);

    /* Subscribe user to card if performing card actions unless user already subscribed/unsubscribed in the past */
    if (shouldBeSubscribed || l.contains('js-organize-assign')) {
      if (!card.isInitSubscriber) {
        card.subscribeValue = true;
        card.isInitSubscriber = true;
      }
    }
  })

  .on('ajax:success', 'bu-card', function onSuccessCardBinder(e) {
    const t = e.target;
    const l = t.classList;

    if (l.contains('js-organize-assign')) {
      CardBinder(this, { snackMsg: t.querySelector('ef-icon').name.includes('plus-circle') ? 'organize_assign' : 'organize_unassign' });
    }
    if (t.tagName === 'EF-COMMENT-FORM') {
      this.querySelector('footer .js-comment bu-icon-block').icon = 'comment-dots:solid';
      this.querySelector('footer .js-comment').classList.add('active');
    }
  })

  .on('ajax:complete ajax:stopped', 'bu-card', debounce((e) => {
    e.target.classList.remove('xhr-started');
    e.target.classList.add('xhr-completed');
  }, 100))

  .on('comment:add', 'bu-card', function onCommentAddCardBinder() {
    CardBinder(this, { card_comments_count: '+1' });
  })

  .on('comment:remove', 'bu-card', function onCommentRemoveCardBinder(e, count) {
    CardBinder(this, { card_comments_count: count });
  })

  .on('cocoon:before-insert', '.js-poll-cocoon, .js-date-cocoon, .js-organize-cocoon, .js-booking-options-cocoon', function onCocoonBeforeInsertCardBinder(e, $insertedItem) {
    const priorities = $('.nested-fields-priority [type="number"]', this).map((i, element) => element.value).get();
    priorities.push(0);

    const priority = Math.max.apply(this, priorities);
    $insertedItem.find('.nested-fields-priority [type="number"]').val(priority + 1);

    const $toggleTime = $insertedItem.find('[data-visibility-id="datetime-time"]');
    $toggleTime.length && $toggleTime.toggleClass('hidden', !e.currentTarget.querySelector('[data-visibility-target="datetime-time"]').checked);
  })

  .on('cocoon:before-insert', '.js-booking-cocoon', (e, $insertedItem) => {
    const $toggleTime = $insertedItem.find('[data-visibility-id="datetime-time"]');
    $toggleTime.length && $toggleTime.toggleClass('hidden', e.currentTarget.querySelector('[data-visibility-target="datetime-time"]').checked);
  })

  .on('cocoon:before-remove', '.js-poll-cocoon, .js-date-cocoon, .js-organize-cocoon, .js-booking-cocoon, .js-booking-options-cocoon', function onCocoonBeforeRemoveCardBinder(e) {
    const cocoonMin = (e.currentTarget.dataset.cocoonMin ? Number(e.currentTarget.dataset.cocoonMin) : 2);
    if ($('.nested-fields:visible', this).length === cocoonMin) {
      e.preventDefault();
    }
  })

  .on('cocoon:after-remove cocoon:after-insert', '.js-poll-cocoon, .js-date-cocoon, .js-organize-cocoon, .js-booking-cocoon, .js-booking-options-cocoon', function onCocoonAfterCardBinder(e) {
    const cocoonMin = (e.currentTarget.dataset.cocoonMin ? Number(e.currentTarget.dataset.cocoonMin) : 2);
    const $el = $(this);
    const k = 'cocoon-disable-remove';

    $('.nested-fields:visible', this).length <= cocoonMin ? $el.addClass(k) : $el.removeClass(k);
  })

  // Clear all Poll card checked options
  .on('click', '.js-poll-clear-btn', (e) => {
    const $el = $(e.target).closest('bu-card');
    $el.find('.card-items input').prop('checked', false);
  });

function setBookingCocoonLabelWidth() {
  const el = $('.js-booking-cocoon');
  if (!el.length) { return; }

  el.find('.nested-fields-label:nth-child(1)').css('width', $('.nested-fields-booking > *:nth-child(1)').innerWidth());
  el.find('.nested-fields-label:nth-child(2)').css('width', $('.nested-fields-booking > *:nth-child(2) .datetime-time').innerWidth());
  el.find('.nested-fields-label:nth-child(3)').css('width', $('.nested-fields-booking > *:nth-child(3) .datetime-time').innerWidth());
}

$(document).on('change', '.js-predefined-availability', (e) => {
  const { value } = e.target;

  if (value === 'custom') {
    $('.js-slots-content').removeClass('hidden');
    $('.js-toggle-timepicker').addClass('hidden');
    setBookingCocoonLabelWidth();

    const cocoonMin = (e.currentTarget.closest('.js-booking-cocoon').dataset.cocoonMin ? Number(e.currentTarget.closest('.js-booking-cocoon').dataset.cocoonMin) : 2);
    const $el = $(e.currentTarget.closest('.js-booking-cocoon'));
    const k = 'cocoon-disable-remove';
    $('.nested-fields:visible', $el).length <= cocoonMin ? $el.addClass(k) : $el.removeClass(k);
  } else {
    $('.js-slots-content').addClass('hidden');
    !document.querySelector('[data-visibility-target="datetime-time"]').checked && $('.js-toggle-timepicker').removeClass('hidden');
  }
});

$(document).on('visibility:toggled', '[data-visibility-target]', () => {
  setBookingCocoonLabelWidth();
  $('.js-predefined-availability').val() === 'custom' && $('.js-toggle-timepicker').addClass('hidden');
});

$(document).on('shown.bs.modal', '.form-modal', () => {
  setBookingCocoonLabelWidth();
});

$(window).on('resize', () => {
  setBookingCocoonLabelWidth();
});

App.lastSync = +new Date();
let workerTimeout;
let fails = 0;

function worker(opts = {}) {
  // set in .env file SYNC_WORKER_DISABLED=1 to disable worker
  if (AppConfig.env.SYNC_WORKER_DISABLED) { return; }

  opts.reset && (fails = 0);
  clearTimeout(workerTimeout);
  if (!AppConfig.user.id) { return; }
  if (!this.pageIsActive) { return; }
  if (App.isSuspended) { return; }

  const query = {};

  query.online_user_status = window.OnlineStatus.queryString();

  const workerId = workerTimeout;

  $.ajax({
    url: '/sync/all.json',
    data: query,
    beforeSend() {
      App.lastSync = +new Date();
    },
  })
    .done((data) => {
      workerId === workerTimeout && triggerEvent(document, 'sync', data);
      // App.syncCount = App.syncCount + 1
      // console.log(App.syncCount)
      fails = 0;
    })
    .fail(() => {
      fails += 1;
    })
    .always((data) => {
      if (data.status === 401) { return; }
      if (fails > 2) { return; }
      workerTimeout = setTimeout(worker, AppConfig.inTestEnv ? 1000 : 5000);
    });
}

let fullStop = false;

function workerStop() {
  clearTimeout(workerTimeout);
}

function workerFullStop() {
  workerStop();
  fullStop = true;
}

window.workerFullStop = workerFullStop;

function workerStart() {
  if (!fullStop) {
    workerStop();
    workerTimeout = setTimeout(worker, 200);
  }
}

window.workerStart = workerStart;

$(document).on('pusher:connected', () => {
  workerStart({ reset: true });
});

document.addEventListener('turbolinks:load', () => {
  fails = 0;
  workerStart();
  // App.syncCount = 0;

  if (AppConfig.user.id && AppConfig.user.isConfirmed) {
    $('.js-trigger-comments > .collapse-comments').collapse('show');
  }
});

// Stop sync workers if page is idle
document.addEventListener('visibility', (e) => {
  e.detail.pageIsActive ? workerStart() : workerStop();
});

// const unread_messages_count = '/';

// Somewhere on in other script
$(document)
  .on('card.create.fm', (e, data) => {
    const $firstCard = $('.js-cards-list bu-card:first, .js-cards-list bu-card-event-line-1:first');
    const $card = $(data.html).addClass('injected');

    const wrapper = document.querySelector('.js-cards-list');
    if (!wrapper) {
      const cardId = $card[0]?.cardId;

      // check if we are on cards details page and load the new card
      if (window.location.pathname.match(/\w+_cards\/\d+$/) && cardId) {
        window.Turbolinks.visit(cardId);
        return;
      }
      window.Turbolinks.clearCache();
      window.Turbolinks.visit(window.location);
      return;
    }

    if ($firstCard.length) {
      $firstCard.before($card);
    } else {
      $('.js-cards-list').append($card);
    }
  })

  .on('card.update.fm', (e, data) => {
    const $originalCard = $(`bu-card[cardId=${data.id}], bu-card-event-line-1[cardId=${data.id}]`);
    const $card = $(data.html).addClass('injected');

    $originalCard.hasClass('card-chain-next') && $card.addClass('card-chain-next');
    $originalCard.replaceWith($card);
  })

  .on('card_assign.update.fm', (e, data) => {
    const el = document.querySelector(`bu-card[cardId="${data.params.card_id}"] .card-item[data-id="${data.params.item_id}"]`);

    el.querySelectorAll('.card-organize-item-check').forEach((elem) => {
      elem.classList.toggle('hidden');
    });
    Snack({ text: I18n.t('snack.assign.success_msg') });
  });
