import I18n from 'i18n';
import Proxy from './proxy_menu';

export default class ContextMenu {
  constructor(el) {
    this.el = el;
    this.el.addEventListener('touchmove', this);
    this.el.addEventListener('touchend', this);
    this.el.addEventListener('contextmenu', this);
    this.detectLongPress();
  }

  detectLongPress() {
    this.timer = setTimeout(() => { this.renderMenu(); }, 800);
  }

  menuHtml(el) {
    const targetAttr = el.target ? `target="${el.target}"` : '';
    const relAttr = el.rel ? `rel="${el.rel}"` : '';

    return `
      <li>
        <a href="${el.href}" ${targetAttr} ${relAttr} class='item-with-icon'>
          <ef-icon name='external-link'></ef-icon>
          <span>${I18n.t('common.open_link')}</span>
        </a>
      </li>
      <li>
        <a href=${el.href} class='item-with-icon js-copy-link'>
          <ef-icon name='copy'></ef-icon>
          <span>${I18n.t('common.copy_link')}</span>
        </a>
      </li>
    `;
  }

  destroy() {
    clearTimeout(this.timer);
    this.el.removeEventListener('touchmove', this);
    this.el.removeEventListener('touchend', this);
    this.el.removeEventListener('contextmenu', this);
  }

  handleEvent(e) {
    if (e.type === 'contextmenu') {
      this.preventAll(e);
    } else if (e.type === 'touchmove') {
      this.destroy();
    } else if (e.type === 'touchend') {
      this.destroy();
    }
  }

  preventAll(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  renderMenu() {
    const menu = document.createElement('div');
    menu.className = 'dropdown-menu';
    menu.innerHTML = this.menuHtml(this.el);

    const trigger = document.createElement('div');
    trigger.setAttribute('data-menu-title', this.el.href);
    trigger.appendChild(menu);

    Proxy($(trigger));
  }
}
