import { AppConfig } from 'src/configuration';
import { Panel } from 'src/attachments';
import I18n from 'i18n';

const ObserverKlass = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
const { IS_ENABLED_TRANSLATE_MODE } = AppConfig.env;
const NODE_TYPE_ELEMENT = 1;

// console.info('IS_ENABLED_TRANSLATE_MODE', IS_ENABLED_TRANSLATE_MODE);

function buildBottomPanel({ key, translate }) {
  const lingoLink = `https://app.lingohub.com/workspace/space_19Qv9Swj9APu-10709/editor?search=segment[key]_contains_${key};project[id]_eq_pr_12VnWJQKHCZC-14207&quickSearchType=KEY`;

  return `
    <div class="panel">
      <h2>${translate}</h2>
      <div><strong>${key}</strong></div>
      <div>Locale: ${I18n.locale}</div>
      <div><a href="${lingoLink}" target="lingohub">View on lingohub</a></div>
    </div>
  `;
}
let panel;

function walk(root) {
  // eslint-disable-next-line compat/compat
  const walker = document.evaluate(
    "//text()[contains(.,'I18nOverride')]",
    root,
    null,
    // eslint-disable-next-line compat/compat
    XPathResult.TEXT_TYPE,
  );

  const nodes = [];
  let node = walker.iterateNext();
  while (node) {
    nodes.push(node);
    node = walker.iterateNext();
  }
  return nodes;
}

function inspect(e) {
  if (e.ctrlKey || e.metaKey || panel.isOpen) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    const info = JSON.parse(e.target.getAttribute('data-translation'));
    panel.render(buildBottomPanel(info));
  }
}

$(document).on('click', '[data-translation]', inspect);

function parseTranslationFromNode(n) {
  const [, key, translate] = n.nodeValue.split(':');
  n.parentNode.title = key;
  const info = {
    key,
    translate,
  };
  n.parentNode.setAttribute('data-translation', JSON.stringify(info));

  // n.parentNode.addEventListener('click', inspect, true);
  n.nodeValue = translate;
}

function parseTranslationForAttribute(attribute, node) {
  if (!node[attribute]) { return; }

  const [, key, translate] = node[attribute].split(':');
  node.title = key;
  node[attribute] = translate;
}

function processTextNodesUnder(el) {
  walk(el).forEach(n => parseTranslationFromNode(n));
  document.querySelectorAll("[placeholder*='I18nOverride']").forEach(n => parseTranslationForAttribute('placeholder', n));
  document.querySelectorAll("[value*='I18nOverride']").forEach(n => parseTranslationForAttribute('value', n));
}

const observer = new ObserverKlass(((mutations) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((n) => {
      if (n.nodeType !== NODE_TYPE_ELEMENT) { return; }

      processTextNodesUnder(n);
    });
  });
}));

if (IS_ENABLED_TRANSLATE_MODE) {
  observer.observe(document, {
    childList: true,
    subtree: true,
    characterData: false,
  });
}

document.addEventListener('turbolinks:load', () => {
  if (!IS_ENABLED_TRANSLATE_MODE) { return; }

  panel = new Panel();

  processTextNodesUnder(document.body);

  observer.observe(document, {
    childList: true,
    subtree: true,
    characterData: false,
  });
});
