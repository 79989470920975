import { AppConfig } from 'src/configuration';

function renderProxyMenu(title) {
  return $([
    '<div class="proxy-menu">',
    '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><ef-icon name="close" aria-hidden="true"></ef-icon></button>',
    '<div class="proxy-menu-content">',
    '<h4 class="proxy-menu-title">',
    title,
    '</h4>',
    '<ul class="proxy-menu-nav"></ul>',
    '</div>',
    '</div>',
  ].join(''));
}

export default function setupProxy($originalMenu) {
  const title = $originalMenu.attr('data-menu-title');
  const $proxyMenu = renderProxyMenu(title);
  $originalMenu.trigger('show.bs.dropdown');
  $proxyMenu.data('original-menu', $originalMenu);

  // Defer so it has time to render
  setTimeout(() => {
    // $proxyMenu.find('.proxy-menu-nav').html(cloneItems($originalMenu));
    $proxyMenu.find('.proxy-menu-nav').html($originalMenu.find('.dropdown-menu > *'));
    $proxyMenu.appendTo(document.body).modal();
  }, 0);

  return $proxyMenu;
}

const shouldSkipProxyMenu = () => window.innerWidth >= 768 || document.body.classList.contains('ct-body');
const shouldShowProxyMenu = () => !shouldSkipProxyMenu();

$(document)
  .on('show.proxyMenu', '.js-proxy-menu', function onShowProxyMenu(e) {
    e.preventDefault();
    if (shouldSkipProxyMenu()) { return; }
    setupProxy($(this));
  })
  .on('click', '.js-proxy-menu .btn-more, .js-proxy-trigger', function onClickProxyMenuMore(e) {
    e.preventDefault();
    if (shouldSkipProxyMenu()) { return; }
    setupProxy($(this).parent());
  })

  // retrigger events from proxyMenu to original menu
  .on('click, ajax:success, ajax:error, ajax:beforeSend', '.proxy-menu', function retrigger(e) {
    const originalMenu = $(this).data('original-menu').get(0);
    originalMenu.dispatchEvent(new Event(e));
  })
  .on('click', '.proxy-menu', function onClickProxyMenuItem() {
    $(this).closest('.proxy-menu').modal('hide');
  })
  .on('hidden.bs.modal', '.proxy-menu', function onHiddenBsModal() {
    const $originalMenu = $(this).data('original-menu');
    $originalMenu.trigger('hidden.bs.dropdown');
    $originalMenu.find('.dropdown-menu').html($('.proxy-menu-nav > *'));
    $(this).remove();
  })

  .on('show.bs.dropdown', '.js-proxy-menu', (e) => {
    if (shouldShowProxyMenu()) {
      e.preventDefault();
    }

    // TODO: Delete after ios app height bug fix
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS && AppConfig.runtime.isMobileApp) {
      window.pageYOffset < 60 && window.scrollTo(0, 60);
    }
    // END: Delete after ios app height bug fix
  })

  .on('click', '.dropdown li, .proxy-menu li', (e) => {
    const item = e.originalEvent.target?.childNodes[0];
    return !item?.classList?.contains('disabled'); // return false -> don't close the dropdown
  });
