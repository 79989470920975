/*  eslint-disable max-classes-per-file */

const PANEL_CLASS = 'js-board';
const PANEL_CLASS_SELECTOR = `.${PANEL_CLASS}`;
const SELECT2_CLASS_SELECTOR = '.select2-container';

// eslint-disable-next-line import/prefer-default-export
export class Panel {
  constructor() {
    this.injected = false;
    this.isOpen = false;
    this.el = this.setupElement();
    this.setupEvents();
  }

  setupEvents() {
    this.closeBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.close();
    });
  }

  setupElement() {
    const el = document.createElement('div');
    el.classList.add('board-v1', PANEL_CLASS);

    const container = `
      <div class="container">
        <a href="#" class="btn btn-v2 t7 js-panel-close">
          <ef-icon name="close"></ef-icon>
        </a>
        <div class="js-content"></div>
      </div>
    `;

    el.innerHTML = container;

    this.closeBtn = el.querySelector('.js-panel-close');
    return el;
  }

  isElementInsidePanel(element) {
    return element.closest(PANEL_CLASS_SELECTOR);
  }

  isElementOutsidePanel(element) {
    return !this.isElementInsidePanel(element);
  }

  isElementInsideSelect2(element) {
    // Select2 search element is outside the Panel
    // select2-selection__choice__remove deletes option it self and isElementOutsidePanel returns null
    return element.closest(SELECT2_CLASS_SELECTOR) || element.classList.contains('select2-selection__choice__remove');
  }

  close() {
    this.isOpen = false;

    this.el.classList.remove('board-open');
    document.body.classList.remove('board-open');
    const event = new Event('panel:closed');
    this.el.dispatchEvent(event);
  }

  open() {
    this.isOpen = true;
    !this.injected && this.inject();
    this.el.classList.add('board-open');
    document.body.classList.add('board-open');
  }

  render(html) {
    this.open();
    this.el.querySelector('.js-content').innerHTML = html;
  }

  inject() {
    document.body.appendChild(this.el);
    this.injected = true;
  }

  destroy() {
    this.injected = false;
    this.isOpen = false;
    this.el.remove();
  }
}

class AjaxPanel extends Panel {
  load(url) {
    $.ajax({ url, headers: { 'X-No-Layout': 1 } })
      .then(result => this.render(result));
  }
}

class Attachment {
  static lastAttachment = null;
  static panel = new AjaxPanel();

  constructor(el) {
    this.el = el;
    this.url = el.href;
    this.file = el.firstChild;
  }

  static eventListener(e) {
    const { panel } = this;
    const selector = '.js-attachment';
    const el = e.target.closest(selector);

    if (panel.isElementInsidePanel(e.target)) { return; }
    if (panel.isElementInsideSelect2(e.target)) { return; }

    if (!el) {
      Attachment.deSelectAll();
      panel.close();
      return;
    }

    e.preventDefault();

    const attachment = new Attachment(el);
    attachment.select();
  }

  static setupEventListener() {
    const { panel } = this;

    document.addEventListener('click', this.eventListener.bind(this));
    document.addEventListener('turbolinks:before-cache', () => {
      Attachment.deSelectAll();
      panel.destroy();
    });
    panel.el.addEventListener('panel:closed', () => Attachment.deSelectAll());
  }

  static start() {
    if (!document.querySelector('.js-attachment')) { return; }

    this.setupEventListener();
  }

  static deSelectOthers(selected) {
    this.lastAttachment && this.lastAttachment !== selected && this.lastAttachment.deSelect();
  }

  static deSelectAll() {
    this.lastAttachment && this.lastAttachment.deSelect();
  }

  deSelect() {
    this.file.classList.remove('active');
    this.lastAttachment = null;
  }

  markAsSelected() {
    this.file.classList.add('active');
    this.lastAttachment = this;
  }

  select() {
    this.panel.load(this.url);
    Attachment.deSelectOthers(this);
    this.markAsSelected();
  }

  get panel() {
    return this.constructor.panel;
  }

  set lastAttachment(attachment) {
    this.constructor.lastAttachment = attachment;
  }

  get lastAttachment() {
    return this.constructor.lastAttachment;
  }

  get selected() {
    return this.constructor.lastAttachment === this;
  }
}

document.addEventListener('fragmentInclude:replaced', () => {
  Attachment.start();
});

document.addEventListener('live:done', () => {
  Attachment.lastAttachment && Attachment.lastAttachment.markAsSelected();
});
