class SrollTo {
  static defaults = {
    duration: 400, // ms
    offset: 16, // px
  };

  constructor(el) {
    this.el = el;
    this.options = this.constructor.defaults;
    this.isEnabled = (this.el.dataset.scrollTo === 'true');
    this.elY = this.getElementY(this.el);
    this.offset = this.pageOffset() + this.options.offset;
    this.duration = this.options.duration;
  }

  getElementY(el) {
    return window.pageYOffset + el.getBoundingClientRect().top;
  }

  pageOffset() {
    const desktopHeader = document.querySelector('.navbar-default .normal-header');
    // const mobileHeader = document.querySelector('.navbar-default .mobile-header'); TODO
    const submenu = document.querySelector('.submenu');

    const offset1 = desktopHeader ? desktopHeader.offsetHeight : 0;
    // const offset2 = mobileHeader ? mobileHeader.offsetHeight : 0; TODO
    const offset3 = submenu ? submenu.offsetHeight : 0;

    return offset1 + offset3;
  }

  start() {
    if (!this.isEnabled) { return; }

    const { duration, offset } = this;
    const startingY = window.pageYOffset;
    const diff = this.elY - startingY - offset;
    let start;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }
}

export default SrollTo;

document.addEventListener('turbolinks:load', () => {
  const el = document.querySelector('[data-scroll-to]');
  if (!el) { return; }

  new SrollTo(el).start();
});
