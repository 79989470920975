import { AppConfig } from 'src/configuration';

const { APP_PREVIOUS_VERSION, APP_VERSION, IS_MOBILE } = App.Mobile;

// 432 000 000 miliseconds
const FIVE_DAYS = 5 * 60 * 60 * 24 * 1000;

const loggedIn = AppConfig.user.id;

const STORAGE_KEY = 'updateAppNoticeShownAt';

const notifiedAt = parseInt(localStorage.getItem(STORAGE_KEY) || 0, 10);
const NOTICE_NOT_SEEN_IN_LAST_5_DAYS = Math.abs(notifiedAt - Date.now()) >= FIVE_DAYS;

const shouldShowNotice = IS_MOBILE && APP_VERSION <= APP_PREVIOUS_VERSION && loggedIn && NOTICE_NOT_SEEN_IN_LAST_5_DAYS;

if (shouldShowNotice) {
  document.addEventListener('turbolinks:load', () => {
    localStorage.setItem(STORAGE_KEY, Date.now());
    setTimeout(() => $.get('/modals/outdated_app'), 2000);
  });
}
