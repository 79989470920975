import debounce from 'lodash/debounce';
import Rails from 'rails-ujs';
import Snack from '@effectivastudio/snackbar';
import I18n from 'i18n';

const formSelector = '[data-autosave-form]';
const formSelectorWithMessage = '[data-autosave-form-msg]';

const debouncedFormSubmit = debounce(({ target }) => {
  if (target.classList.contains('select2-search__field')) { return; }
  Rails.fire(target.closest('form'), 'submit');
}, 500);

$(document)
  .on('input', formSelector, debouncedFormSubmit)
  .on('select2:select select2:unselect', formSelector, debouncedFormSubmit)
  .on('ajax:beforeSend', formSelectorWithMessage, () => { Snack({ text: I18n.t('snack.saving') }); })
  .on('ajax:success', formSelectorWithMessage, () => { Snack({ text: I18n.t('snack.saved') }); })
  .on('ajax:error', formSelectorWithMessage, () => { Snack({ text: I18n.t('snack.generic_error_msg'), customClass: 'snackbar-danger' }); });
