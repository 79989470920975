const cloneItem = (el, target) => {
  const clonedEl = el.firstElementChild.cloneNode(true);

  clonedEl.querySelectorAll('input').forEach((item) => {
    item.value = '';
  });

  el.insertBefore(clonedEl, target);
  clonedEl.querySelector('input').focus();
};

const toggleDisableClass = (el) => {
  const min = el.dataset.blockCloneMin || 1;
  const count = el.childElementCount - 1;

  count <= min ? el.classList.add('disable-remove') : el.classList.remove('disable-remove');
};

const removeItem = (el, target) => {
  const countMin = el.dataset.blockCloneMin || 1;
  const count = el.childElementCount - 1;
  if (count <= countMin) { return; }

  target.parentNode.remove();
};

$(document).on('click', '[data-block-clone-trigger]', (e) => {
  e.preventDefault();
  const parent = e.currentTarget.closest('[data-block-clone]');
  if (!parent) { return; }

  cloneItem(parent, e.currentTarget);
  toggleDisableClass(parent);
});

$(document).on('click', '[data-block-clone-remove]', (e) => {
  e.preventDefault();
  const parent = e.currentTarget.closest('[data-block-clone]');
  if (!parent) { return; }

  removeItem(parent, e.currentTarget);
  toggleDisableClass(parent);
});
