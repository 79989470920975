import I18n from 'i18n';
import { timestampToDate } from './pure_utils';

export function smartDateFormatter(timestamp) {
  const ONE_DAY = 86400000;

  const currentDate = new Date();
  const date = timestampToDate(timestamp);
  const distance = currentDate - date;
  let format;

  if (currentDate.getFullYear() !== date.getFullYear()) {
    format = 'long';
  } else if (distance > ONE_DAY) {
    format = 'short';
  } else {
    format = 'time';
  }

  return I18n.l(`date.formats.${format}`, date);
}

export const isPartOfElementVisible = (el, parent) => {
  if (!el || !parent) { return false; }

  const childPos = el.getBoundingClientRect();
  const parentPos = parent.getBoundingClientRect();

  const relativePos = {};
  relativePos.top = childPos.top - parentPos.top;
  relativePos.right = childPos.right - parentPos.right;
  relativePos.bottom = childPos.bottom - parentPos.bottom;
  relativePos.left = childPos.left - parentPos.left;

  return (
    relativePos.top + childPos.height >= 0
    && relativePos.left >= 0
    && relativePos.top <= parentPos.height
    && relativePos.right <= parentPos.width
  );
};

export const getIOSVersion = () => {
  if (/iP(hone|od|ad)/.test(navigator.userAgent)) {
    const v = (navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
  return false;
};

// eslint-disable-next-line compat/compat
export const isChromiumBrowser = () => !!navigator.userAgentData && navigator.userAgentData.brands.some(data => data.brand === 'Chromium');
