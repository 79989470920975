import Turbolinks from 'turbolinks';
import I18n from 'i18n';

$(document)

  // Only for user account edit_user form
  .on('ajax:success:inline', '[data-show="/account/basic"]', (e, originalEvent) => {
    const xhr = originalEvent.detail[2];
    const data = $(xhr.response).data();
    data.fullName && $('.username').html(data.fullName);
  })

  // Open confirmation modal after inline edit of users phone
  .on('ajax:success:inline:updatePhone', (e) => {
    const link = $('.js-phone-confirmation-link', e.target).attr('href');
    link && $.get(link);
  })

  // After user successfully confirms a phone
  .on('phone.create.fm', () => {
    $('.js-phone-confirmation-link').trigger('updatePhone:done');
    $('[data-show="/account/settings/phone"]').trigger('reload');
  })

  .on('ajax:success:inline:updateLocale', (e) => {
    const { lang } = e.target.querySelector('[data-lang]').dataset;

    document.documentElement.lang = lang;
    I18n.locale = lang;

    document.dispatchEvent(new Event('i18n-change-locale'));
    Turbolinks.visit(window.location.href, { action: 'replace' });
  })

  .on('ajax:beforeSend', '.js-cancel-delete-request', (e) => {
    $(e.currentTarget).closest('.message').hide();
  });
