import { reloadElement } from 'src/utils';

function handleEvent() {
  // Dispatch new native event that we can listen
  // as we cannot listen jQuery events with addEventListener
  document.dispatchEvent(new Event('card-booking-modal-updated'));

  // Update booking slot list element.
  reloadElement('.js-reservations', { morph: false });
}

$(document).on('card_booking_slot.create.fm', handleEvent);
$(document).on('card_booking_slot.update.fm', handleEvent);
