// cspell:disable
import $ from 'jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import i18n from 'i18n';

// Embed content from https://github.com/uxsolutions/bootstrap-datepicker/blob/master/js/locales/bootstrap-datepicker.de.js
$.fn.datepicker.dates.de = {
  days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  daysShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  today: 'Heute',
  monthsTitle: 'Monate',
  clear: 'Löschen',
  weekStart: 1,
  format: 'dd.mm.yyyy',
};

$.fn.datepicker.dates.fr = {
  days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  daysShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
  daysMin: ['d', 'l', 'ma', 'me', 'j', 'v', 's'],
  months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
  monthsShort: ['janv.', 'févr.', 'mars', 'avril', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
  today: "Aujourd'hui",
  monthsTitle: 'Mois',
  clear: 'Effacer',
  weekStart: 1,
  format: 'dd/mm/yyyy',
};

$.fn.datepicker.dates.it = {
  days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  daysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  daysMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  monthsShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  today: 'Oggi',
  monthsTitle: 'Mesi',
  clear: 'Cancella',
  weekStart: 1,
  format: 'dd/mm/yyyy',
};

// Application setup
$.fn.datepicker.defaults.language = i18n.locale;
$.fn.datepicker.dates.de.format = 'dd/mm/yyyy';
$.fn.datepicker.dates.en.format = 'dd/mm/yyyy';
$.fn.datepicker.defaults.weekStart = 1;
$.fn.datepicker.defaults.todayHighlight = true;
$.fn.datepicker.defaults.zIndexOffset = 200;
// cspell:enable
