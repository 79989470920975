// MOBILE NAV SWIPE LEFT RIGHT

import triggerEvent from 'lib/utils/trigger_event';

class NavSwipe {
  static defaults = {
    triggerArea: 100, // px
    ignoreArea: 30, // px
    touchDuration: 200, // ms
    openTouchDistance: 160, // px
    blockedElements: '.submenu', // css class
    swipingClass: 'swipe-menu-active', // css class
    openMenuClass: 'user-dropdown-open', // css class
  };

  constructor(el) {
    this.el = el;
    this.menu = this.el.querySelector('.dropdown-menu');
    this.options = this.constructor.defaults;
    this.boundTouchStart = this.touchStart.bind(this);
    this.boundTouchMove = this.touchMove.bind(this);
    this.boundTouchEnd = this.touchEnd.bind(this);
  }

  getFirstTouch(event) {
    return (event.touches || event.originalEvent.touches)[0]; // browser API || jQuery
  }

  getLastTouch(event) {
    return event.changedTouches[0];
  }

  resetAutoHideHeader() {
    document.querySelector('.auto-hide-header')?.removeAttribute('style');
    document.querySelector('.auto-hide-subheader')?.removeAttribute('style');
  }

  toggleMenu() {
    document.body.classList.contains(this.options.openMenuClass) ? this.closeMenu() : this.openMenu();
  }

  openMenu() {
    triggerEvent(document.body, 'scrollPosition:save');
    document.body.classList.add(this.options.openMenuClass);
  }

  closeMenu() {
    document.body.classList.remove(this.options.openMenuClass);
    triggerEvent(document.body, 'scrollPosition:restore');
  }

  isMenuOpened() {
    return document.body.classList.contains(this.options.openMenuClass);
  }

  setSwipingClass() {
    document.body.classList.add(this.options.swipingClass);
  }

  isMenuSwiping() {
    return document.body.classList.contains(this.options.swipingClass);
  }

  isBackGesture() {
    return this.firstTouch.clientX < this.options.ignoreArea;
  }

  isForwardGesture() {
    return this.firstTouch.clientX > window.innerWidth - this.options.ignoreArea;
  }

  isGesture() {
    return this.isBackGesture() || this.isForwardGesture();
  }

  isTriggerArea() {
    return this.firstTouch?.clientX < this.options.triggerArea;
  }

  startTouchTimer() {
    this.timeout = setTimeout(() => {
      this.longTouch = true;
    }, this.options.touchDuration);
  }

  stopTouchTimer() {
    clearTimeout(this.timeout);
  }

  touchEndReset() {
    this.menu.removeAttribute('style'); // reset dynamic styles
    document.body.classList.remove(this.options.swipingClass);
    this.firstTouch = null;
    this.dragEnabled = null;
    this.swipeDirection = null;
    this.longTouch = null;
    this.stopTouchTimer();
  }

  detectSwipeDirection(event) {
    const xUp = event.touches[0].clientX;
    const yUp = event.touches[0].clientY;
    const xDiff = this.firstTouch.clientX - xUp;
    const yDiff = this.firstTouch.clientY - yUp;
    let direction;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        direction = 'left';
      } else {
        direction = 'right';
      }
    } else if (yDiff > 0) {
      direction = 'up';
    } else {
      direction = 'down';
    }

    return direction;
  }

  getMenuOffset() {
    const style = window.getComputedStyle(this.menu);
    const transformMatrix = new window.WebKitCSSMatrix(style.transform);

    return transformMatrix.m41 - this.firstTouch.clientX;
  }

  calculateMenuPosition(xMousePosition) {
    let menuPosition = xMousePosition + this.menuOffset;

    if (menuPosition < 0) {
      menuPosition = 0;
    } else if (menuPosition > this.menu.offsetWidth) {
      menuPosition = this.menu.offsetWidth;
    }

    return Math.floor(menuPosition);
  }

  dragMenuX(xMousePosition) {
    this.menu.style.transform = `translateX(${this.calculateMenuPosition(xMousePosition)}px)`;
  }

  isElementBlocked(target) {
    return !!target.closest(this.options.blockedElements);
  }

  isDraggable(target) {
    return !this.isElementBlocked(target) && !this.isBackGesture() && (this.isTriggerArea() || this.isMenuOpened());
  }

  touchStart(e) {
    this.firstTouch = this.getFirstTouch(e);
    this.dragEnabled = this.isDraggable(e.target);

    if (!this.dragEnabled) { return; }

    this.isMenuOpened() && this.isGesture() && e.preventDefault(); // Prevent iOS swipe back and forward when menu is open
    this.startTouchTimer();
    this.menuOffset = this.getMenuOffset();
    this.menu.style.transition = 'none';
  }

  touchMove(e) {
    if (!this.dragEnabled) { return; }

    if (this.swipeDirection) {
      e.preventDefault();
      this.dragMenuX(e.changedTouches[0].clientX);
      return;
    }

    this.dragEnabled = false;

    switch (this.detectSwipeDirection(e)) {
      case 'left':
        if (this.isMenuOpened()) {
          this.setSwipingClass();
          this.swipeDirection = 'left';
          this.dragEnabled = true;
        }
        break;
      case 'right':
        if (!this.isMenuOpened()) {
          this.swipeDirection = 'right';
          this.setSwipingClass();
          this.resetAutoHideHeader();
          this.dragEnabled = true;
        }
        break;
      default:
        break;
    }
  }

  touchEnd(e) {
    if (!this.dragEnabled) { return; }

    if (this.swipeDirection) {
      this.lastTouch = this.getLastTouch(e);
      this.xEnd = this.lastTouch.clientX > 0 ? this.lastTouch.clientX : 0; // Sometimes lastTouch in negative number (iOS back/forward swipe)

      const openTouchDistance = Math.abs(this.firstTouch.clientX - this.xEnd);

      if (!this.longTouch) {
        this.toggleMenu();
      } else if (this.swipeDirection === 'right') {
        if (openTouchDistance > this.options.openTouchDistance) {
          this.openMenu();
        }
      } else if (this.swipeDirection === 'left') {
        if (openTouchDistance > this.options.openTouchDistance) {
          this.closeMenu();
        }
      }
    }
    this.touchEndReset();
  }

  destroy() {
    this.closeMenu();
    document.removeEventListener('touchstart', this.boundTouchStart, { passive: false });
    document.removeEventListener('touchmove', this.boundTouchMove, { passive: false });
    document.removeEventListener('touchend', this.boundTouchEnd);
  }

  setup() {
    document.addEventListener('touchstart', this.boundTouchStart, { passive: false });
    document.addEventListener('touchmove', this.boundTouchMove, { passive: false });
    document.addEventListener('touchend', this.boundTouchEnd);
  }
}

export default NavSwipe;

let navSwipe;
document.addEventListener('turbolinks:load', () => {
  const menu = document.querySelector('.js-user-menu-dropdown');
  if (!menu) { return; }

  navSwipe = new NavSwipe(menu);
  navSwipe.setup();
});

document.addEventListener('turbolinks:before-render', () => {
  navSwipe && navSwipe.destroy();
});
