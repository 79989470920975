function formDataValues(form) {
  const data = new FormData(form);
  const out = [];
  data.forEach((item) => {
    out.push(item);
  });
  return out;
}

document.addEventListener('turbolinks:load', () => {
  const collection = $('[data-edit]');

  function close(element) {
    element.innerHTML = element.dataset.inlineEditHTML;
    element.closest('.js-inline-edit').classList.remove('edit', 'loading');
    element.dataset.inlineEditOpen = false;
  }

  function closeAll() {
    collection.each((i, item) => {
      item.dataset.inlineEditOpen && close(item);
    });
  }

  collection.each((i, element) => {
    const $c = $(element);
    const d = $c.data();

    if (d.inlineFormed) { return; }

    $c.data('inlineFormed', true);

    let $formRequest = $.ajax({ url: d.edit, headers: { 'X-Inline-Form': 1 } });
    let formValues = '';
    let initShowHTML;
    element.dataset.inlineEditHTML = $c.html();
    element.dataset.inlineEditOpen = false;

    let $handlerElement = $c.closest('.js-inline-edit');
    $handlerElement = $handlerElement.length ? $handlerElement : $c;

    $handlerElement.on('click', '.header, .js-inline-trigger', (e) => {
      e.preventDefault();

      if (element.dataset.inlineEditOpen === 'true') {
        close(element);
        return;
      }

      closeAll();

      $formRequest.state() === 'pending' && $handlerElement.addClass('loading');
      initShowHTML = $c.html();
      $formRequest.done((response) => {
        $c.html(response);
        const $form = $c.find('form');
        $form.enableClientSideValidations();
        formValues = formDataValues($form.get(0));
        $form.find('[autofocus]').focus();
        $handlerElement.addClass('edit');
        $handlerElement.removeClass('loading');
        element.dataset.inlineEditOpen = true;
      });
    });

    $handlerElement.on('click', '.js-inline-cancel', (e) => {
      e.preventDefault();
      element.dataset.inlineEditHTML = initShowHTML;
      close(element);
    });

    if (d.preventCleanup === undefined) {
      $c.on('cleanup.turbolinks', () => close(element));
    }

    $c.on('reload', () => {
      $handlerElement.addClass('loading');
      $formRequest = $.ajax({ url: d.edit, headers: { 'X-Inline-Form': 1 } });
      $.ajax({ url: d.show, headers: { 'X-Inline-Form': 1 } }).done((response) => {
        element.dataset.inlineEditHTML = response;
        close(element);
      });
    });

    $c.on('ajax:before', (e) => {
      if (e.target.className.indexOf('js-inline') < 0) { return; }
      $(e.target).trigger(`${e.type}:inline`);

      const newFormValues = formDataValues($c.find('form').get(0));
      const hasEmptyRequiredFields = $c.find('[required]').filter(function filterRequiredFields() {
        const val = $(this).val();
        if (Array.isArray(val)) {
          return val.map(s => s.trim()).filter(String).length === 0;
        }

        return val === '';
      }).length;

      if (!hasEmptyRequiredFields && newFormValues === formValues) {
        e.preventDefault();
        close(element);
      }
    });

    $c.on('ajax:beforeSend', (e) => {
      if (e.target.className.indexOf('js-inline') < 0) { return; }
      $(e.target).trigger(`${e.type}:inline`);

      $handlerElement.addClass('loading');
      e.detail[0].setRequestHeader('X-Inline-Form', 1);
    });

    $c.on('ajax:success', (e) => {
      if (e.target.className.indexOf('js-inline') < 0) { return; }
      $c.trigger(`${e.type}:inlineBefore`, e);

      const inlineId = e.target.dataset.inline;
      const xhr = e.detail[2];
      const { response } = xhr;

      // const isJs = xhr.getResponseHeader('content-type').includes('text/javascript');
      const isRedirect = response.includes('Turbolinks.visit');

      $handlerElement.removeClass('loading');

      if (isRedirect) return;

      const $tmp = $('<div />').html(response);
      if (!$tmp.find('.has-error').length) {
        element.dataset.inlineEditHTML = e.isDefaultPrevented() ? e.target : response;
        close(element);
        $formRequest = $.ajax({ url: d.edit, headers: { 'X-Inline-Form': 1 } });
        $c.trigger(`${e.type}:inline`, e);
        inlineId && $c.trigger(`${e.type}:inline:${inlineId}`, e);
        return;
      }
      $c.html(response);
    });
  });
});
