import triggerEvent from 'lib/utils/trigger_event';

// *** INIT *** //
// set target element(s) to hide with data attribute data-visibility-target
// <input type="checkbox" data-visibility-target="datetime-time" />
// and reference(s) with data attribute data-visibility-id
// <div data-visibility-id="datetime-time"></div>

// *** TOGGLE TEXT *** //
// visibility_text_show = "String"
// visibility_text_hide = "String"

// *** STATUS *** //
// visibility_status = boolean => default: true

function elementsByVisibilityTarget(target) {
  return document.querySelectorAll(`[data-visibility-id="${target}"]`);
}

$(document)
  .on('change', '[data-visibility-target], .js-toggle-visibility', (e) => {
    let { visibilityTarget } = e.currentTarget.dataset;

    if (e.currentTarget.tagName === 'SELECT') {
      // Every select can have a visibility section where can group visibilityTargets
      // We hide all matching sections before showing selected one
      const { visibilitySelectSectionId } = e.currentTarget.dataset;
      if (visibilitySelectSectionId) {
        document.querySelectorAll(`[data-visibility-select-section="${visibilitySelectSectionId}"]`)
          .forEach(d => d.classList.add('hidden'));
      }

      const { options, selectedIndex } = e.currentTarget;
      const currentOption = options[selectedIndex];
      visibilityTarget = currentOption.dataset.visibilityTarget;

      Array.from(options).forEach((o) => {
        const optionVisibilityTarget = o.dataset.visibilityTarget;
        const shouldHide = optionVisibilityTarget !== visibilityTarget;

        elementsByVisibilityTarget(optionVisibilityTarget)
          .forEach((d) => {
            let elementShouldHide = shouldHide;
            if (currentOption.dataset.visibilityHideSection !== undefined
                && d.dataset.visibilitySection !== undefined
                && currentOption.dataset.visibilityHideSection === d.dataset.visibilitySection) {
              elementShouldHide = true;
            }

            d.classList[elementShouldHide ? 'add' : 'remove']('hidden');
          });
      });
    } else {
      const { visibilityInvert } = e.currentTarget.dataset;
      const isChecked = e.currentTarget.checked;

      elementsByVisibilityTarget(visibilityTarget)
        // .forEach(d => d.classList[isChecked ? 'remove' : 'add']('hidden'));
        .forEach((d) => {
          if (visibilityInvert) {
            d.classList[isChecked ? 'add' : 'remove']('hidden');
          } else {
            d.classList[isChecked ? 'remove' : 'add']('hidden');
          }
        });
      triggerEvent(e.currentTarget, 'visibility:toggled');
    }
  });

// This is only used for organize card
// app/views/public/cards/organize_cards/_organize_item_fields.html.erb
function toggleTextOn(button) {
  const input = document.getElementById(button.getAttribute('for'));

  const { visibilityStatus, visibilityTextShow, visibilityTextHide } = input.dataset;

  const isVisible = visibilityStatus === 'true';
  const text = isVisible ? visibilityTextShow : visibilityTextHide;

  input.dataset.visibilityStatus = String(!isVisible);

  button.innerHTML = text;
}

$(document)
  .on('click', '.organize_card_items_goal_required label', (e) => {
    const button = e.currentTarget;
    const amount = button.closest('.nested-fields').querySelector('.nested-fields-goal');
    amount.classList.toggle('hidden');

    toggleTextOn(button);
  });
