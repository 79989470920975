import { AppConfig } from 'src/configuration';

import CommentBinder from './data_binders/comment_binder';

function commentsMutationObserver(n) {
  if (n.classList && n.classList.contains('card-comment')) {
    const editLink = '> .card-comment-content > .self .js-comment-edit';
    const deleteLink = '> .card-comment-content > .self .js-comment-delete';
    const reportLink = '> .card-comment-content > .self .js-comment-report';

    const isCommentOwner = Number(n.dataset.ownerId) === AppConfig.user.id;
    const isGroupAdminOwner = n.closest('bu-card').dataset.adminIds.split(',').map(Number).includes(AppConfig.user.id);
    const canEditComment = isCommentOwner;
    const canDeleteComment = isCommentOwner || isGroupAdminOwner;
    const canReportComment = !isCommentOwner;

    // Remove comment edit action if current user is not comment owner
    !canEditComment && $(editLink, n).parent().remove();

    // Remove comment delete action if current user is not comment owner or group admin
    !canDeleteComment && $(deleteLink, n).parent().remove();

    // Remove comment report action if current user
    !canReportComment && $(reportLink, n).parent().remove();
  }
}

App.mutation_observers.push(commentsMutationObserver);

$(document)

  .on('shown.bs.collapse', '.collapse-comments', (e) => {
    $(e.currentTarget).find('form').enableClientSideValidations();
  })

  .on('hide.bs.collapse', '.collapse-comments', (e) => {
    $(e.currentTarget).closest('.card-comment').removeClass('active');
  })

  .on('click', '.js-comment-edit', (e) => {
    e.preventDefault();
  })

  // Load all comments and replies
  .on('fragmentInclude:replaced', (e) => {
    e.target.querySelectorAll('.js-comment-replies').forEach((el) => {
      if (el.dataset.count === '0') { return; }

      $(el).collapse();
    });
  })
  .on('comment:add', '.card-comment', (e) => {
    CommentBinder(e.currentTarget, { replies_count: '+1' });
  })

  .on('comment:remove', '.card-comment', (e, count) => {
    CommentBinder(e.currentTarget, { replies_count: count });
  });
