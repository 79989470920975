import { Snackbar } from '@effectivastudio/snackbar';

Snackbar.configure({
  pos: 'bottom-right',
  backgroundColor: null,
  textColor: null,
  actionTextColor: null,
  customClass: 'snackbar-default',
  actionText: '<ef-icon name="close"></ef-icon>',
});
